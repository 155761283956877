import React,{useState, useEffect} from "react";
// import adminpanelimg from "../Images/admin panel.jpg";
import adminpanelimg from "../Images/administation.png";
import { NavLink } from "react-router-dom";
import './AdminPanel.css'

const AdminPanel = () => {
  const auth = localStorage.getItem("user");


  const [blog, setBlog] = useState([]);

  useEffect(() => {
    getBlog();
  }, []);

  // For get blogs on admin panel page
  const getBlog = async () => {
    let result = await fetch("http://localhost:5000/blogs");
    result = await result.json();
    setBlog(result);
  };

  // For Delete Blog from Admin panel page
  const deleteBlog = async(id)=>{
    let result= await fetch(`http://localhost:5000/blog/${id}`,{
      method:'Delete'
    });
    result= await result.json()
    if(result)
      {
        getBlog(); 
      }
  };

  return (
    <div className="admin-panel-avcom-component">
      <div className="admin-panel-image-container">
        <img src={adminpanelimg} alt="xxx" />
      </div>
      {/* <div className="text-of-home-page">*/}
        {/* <div className="navigation-of-admin-panel">
          {" "}
          <NavLink className="avcom-navlink-of-admin-panel" to="/add-blog">
            Add-Blog
          </NavLink>{" "}
          {"  "}
          <NavLink className="avcom-navlink-of-admin-panel" to="/about-us">
            Form-Data
          </NavLink>
        </div> */}
      {/* </div>  */}



      <div className="welcome-msg-for-admin-panel-container">

      <div className="text-of-home-page">

        <p>Welcome to Admin Panel</p>
        <p style={{fontSize:'1rem', color:'#b01010'}}>Welcome Mr.  {JSON.parse(auth).name}</p>
        
      </div>
      <br /><br />  
      <div className="navigation-of-admin-panel">
          {" "}
          <NavLink className="avcom-navlink-of-admin-panel" to="/add-blog">
            Add-Blog
          </NavLink>{" "}
          {"  "}
          <NavLink className="avcom-navlink-of-admin-panel" to="/about-us">
            Form-Data
          </NavLink>
        </div>

{/* Showing blogs on admin panel */}
        <h2 className="blog-heading-blog-frontend">Blogs</h2>
      <div className="each-blog-conainer-frontend">
     
      {blog.map((item, index) => (
          <ul className="each-blog-container-of-frontend-box">
            {/* <li>{index+1}</li> */}
            <li className="heading-of-blog-page-box">{item.title}</li>
            <li className="blog-image-frontend-image-container">{item.image}</li>
            <li className="blog-details-text-blog-page">{item.detailText}</li>
            <li className="author-of-particualr-blog">Author:- {item.author}</li>
         
            <button className="read-more-button-of-blog-page">
              Read more..
            </button>
            <br />
            <div className="delete-and-modify-btn-container-admin-panel">
            <button className="delete-blog-btn-admin-panel" onClick={()=>deleteBlog(item._id)}>
             Delete
            </button>
            <button className="modify-blog-btn-admin-panel" onClick={()=>deleteBlog(item._id)}>
             Modify
            </button>
            </div>
          </ul>
        ))}
        
      </div>
      </div>



      
    </div>
  );
};

export default AdminPanel;
