import React from 'react';
import { Link } from 'react-router-dom';
import './ThankYou.css'; // Separate CSS file for styling

const ThankYou = () => {
  return (
    <div className="thank-you-container">
      <h1 className="thank-you-title">Thank You!</h1>
      <p className="thank-you-message">Your form has been successfully submitted. We will get back to you shortly.</p>
      <Link to="/" className="home-link">
        Go Back to Home
      </Link>
    </div>
  );
};

export default ThankYou;
