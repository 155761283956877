import React, { useState,useEffect } from 'react'
import {useNavigate,Link} from 'react-router-dom'
import './Signup.css'
import singup from '../Images/signup.jpg'
import { FaEye } from "react-icons/fa";

const Signup = () => {

  const navigate= useNavigate();
    const[name, setName]=useState('');
    const[email, setEmail]=useState('');
    const[password, setPassword]=useState('');
    const[hidePassword, showPassword]=useState(false)

    useEffect(()=>{
      const auth=localStorage.getItem('user');
      if(auth){
        navigate('/')
      }
    })

    const collectSignupData=async()=>{
        let result= await fetch('http://localhost:5000/register',{
          method:'post',
          body:JSON.stringify({name, email, password}),
          headers:{
            'Content-type': 'application/json'
          },
        });

        result= await result.json()
        console.warn(result)
        if(result)
        {
          localStorage.setItem('user',JSON.stringify(result));
          navigate('/')
        }

    }
  return (
    <div className="home-component">
    <div className="home-image-container">
      <img src={singup} alt="xxx" />
    </div>
    <div className="text-of-home-page">
      <p data-text="REGISTER"> SignUp / Login </p>
    </div>

<div className="input-form-signup-container">
    <h1 className="input-form-singup-heading">
Register
    </h1>

    <div className="input-box-signup-box">
        <input type="text" className='inputBox' value={name} onChange={(e)=>setName(e.target.value)} placeholder='Enter name here'/>
        <input type="email" className='inputBox' value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Enter email here'/>

<div className="input-box-password-cntainer">
        <input type={hidePassword?'text':'password'} className='inputBox password-inp-box' value={password} onChange={(e)=>setPassword(e.target.value)} placeholder='Set Your Password'/> 
        
        {hidePassword? <button onClick={()=>showPassword(!hidePassword)}  className='hide-show-button-password showed-password'><FaEye/> </button>:<button onClick={()=>showPassword(!hidePassword)}  className='hide-show-button-password hide-passord-eye'><FaEye/></button>
        
    }
    
    </div>

        <br /> <br /><br />

        
        <button className="submit-signup-button" onClick={collectSignupData}>Signup</button>
        <br /><br />

        <div className="sign-up-btn-in-login">
          <p>Already have a account..</p> <Link to='/login'>Login</Link>
        </div>
    </div>
</div>

    </div>
  )
}

export default Signup
