import React from "react";
import aboutusimg from "../Images/aboutusdp.jpg";
import "./Aboutus.css";
import weproimg from "../Images/weProvide.jpg";
const Aboutus = () => {
  return (
    <div className="home-component">
      <div className="home-image-container">
        <img src={aboutusimg} alt="xxx" />
      </div>
      <div className="text-of-home-page">About AVCOM</div>

      {/* Our Product and services */}

      <div className="our-product-and-services-box About-avcom-about-us-sec">
        <div className="product-service-text-box ">
          <h3 className="color-of-heading-is-orange">About Avcom</h3>
          <p>
            <b>AVCOM NETWORK TECHNOLOGIES</b>, one of the leading IT solutions
            company in Delhi/NCR, specialize in providing complete end to end
            solutions in Computers, Networking, IT and Solar Lighting systems
            (Street lights, Home Lighting System, Solar Water Heater etc.)
            Another area of specialization, among other services, is providing
            Annual Maintenance Services for computers and accessories to leading
            companies. <br /> <br />
            We are empanelled with IT department of Delhi Government for
            supporting and maintaining crucial Computer installations in Delhi
            Government offices. We are guided and driven by the corporate
            philosophy to deliver not just the hardware box but the complete
            hardware & software solutions to meet the complete requirement of
            the customer. Ever since its inception, we have continued with this
            philosophy and have created a niche place for ourselves in IT
            industry in Delhi. We are also authorized Channel partners of M/s
            Hewlett & Packard (HP) for marketing their complete range of
            Desktops, Laptops and other products.
          </p>
        </div>

        <div className="product-service-text-box">
          <h3 className="color-of-heading-is-orange">Inception</h3>
          <p>
            {" "}
            <b>AVCOM NETWORK TECHNOLOGIES</b> was setup in 1997 to facilitate a
            scientific approach on collecting and analyzing information to
            support and optimize business operations. System integration
            services, encompassing complete hardware solutions, software
            solutions and an innovative 95% system uptime maintenance technology
            paved the way for creation of Avcom Network Technologies. Avcom
            Network Technologies went on to establish a successful track record
            of executing large turnkey projects, SOHO solutions and providing
            “State of the Art” computers & communication projects.{" "}
          </p>
          <h3 className="color-of-heading-is-orange">Mission & Vision</h3>
          <p>
            <span
              className="color-of-heading-is-orange"
              style={{ fontWeight: "bold" }}
            >
              Mission:
            </span>{" "}
            Empowering government departments with cutting-edge IT solutions,
            ensuring seamless operations through reliable hardware and
            innovative software, fostering efficiency, transparency, and digital
            transformation. <br />
            <span
              className="color-of-heading-is-orange"
              style={{ fontWeight: "bold" }}
            >
              Vision:
            </span>{" "}
            To be the trusted partner for government entities, leading the way
            in providing tailored, secure, and advanced IT solutions that drive
            public service excellence and societal progress.
          </p>
        </div>
      </div>

      <div className="we-provide-full-container">
        <div className="about-us-we-provide-image-container">
          <h2 className="text-of-we-provide-you-best">
            We Provide You Best...
          </h2>
        </div>
      </div>

      {/* Teams */}
      {/* <div className="our-team-full-container">
        <div className="our-team-headings-container">
          <h2 className="text-of-our-team">Our Team</h2>

          <h6 className="text-of-our-team-yet-to-come">Yet to come..</h6>
        </div>
      </div> */}

      <div className="our-product-and-services-box About-avcom-about-us-sec more-about-ant-full-container-of-onebox">
        <div className="product-service-text-box more-about-ant-product-services ">
          <h3 className="color-of-heading-is-orange">PRODUCTS & SERVICES:-</h3>
          <p>
            Today AVCOM NETWORK TECHNOLOGIES develops Markets & Supports total
            IT solution. Its areas of operations are as follows:-
          </p>
        </div>

        <div className="product-service-text-box product-service-text-box-more-about-ant">
          <h3
            className="color-of-heading-is-orange"
            style={{ fontSize: "1.7rem" }}
          >
            A. COMPUTER & NETWORKING SOLUTIONS
          </h3>
          <p>
            Complete computerized solutions i.e. Hardware, Software, Training in
            Support for all type of Industries like PSUS, Banking, Corporate,
            Telecom, Manufacturing, Engineering Institutions, Defense etc.{" "}
            <br />
            AVCOM NETWORK TECHN OLOGIES maintains Computer Systems &IT Products
            in many leading Government Organizations & departments like
            Directorate of Training & Technical Education Delhi, Delhi
            Technological University, Department of Audit, Delhi Subordinate
            Services Selection Board, Delhi Dispute Resolution Society, Drugs
            Control Department, MTNL, Delhi Jal Board, Secretariat of GOVT. of
            NCT of Delhi, Delhi State Health Mission, Department of Delhi Home
            Guards, GTB Hospital, Attar Sain Hospital, Transport Department,
            Delhi, ITIs at Dheerpur, Jahangirpuri, Mori gate etc, Indian Army,
            to name a few.
          </p>
        </div>
        <div className="product-service-text-box product-service-text-box-more-about-ant">
          <h3
            className="color-of-heading-is-orange"
            style={{ fontSize: "1.5rem" }}
          >
            A1. HARDWARE
          </h3>
          <p>
            The Product range includes PC’s to multi – Enterprise servers and
            networking products to home computers etc.
            <ul>
              <li>Desktop Computers</li>
              <li>Laptop Computers</li>
              <li>Computer Peripherals</li>
            </ul>
            <h4 className="inner-heading-of-about-ant">Computers Rental</h4>
            <ul>
              <li>Desktop Computers Rental</li>
              <li>Laptop Computers</li>
              <li>Projectors</li>
              <li>Printers & UPS</li>
            </ul>
            <h4 className="inner-heading-of-about-ant">
              Maintenance & Servicing
            </h4>
            <ul>
              <li>Annual maintenance contract of computers & peripherals</li>
              <li>On call Service Visits</li>
            </ul>
          </p>
        </div>
        <div className="product-service-text-box product-service-text-box-more-about-ant">
          <h3
            className="color-of-heading-is-orange"
            style={{ fontSize: "1.5rem" }}
          >
            A2. NETWORKING:-
          </h3>
          <p>
            We undertake complete Networking projects with routing, switching
            and wireless solutions for industries, institutions and enterprises.
            We provide cutting edge solutions for all IT needs, be it web
            designing, search engine optimization, web site promotion, web
            application development or Business process applications
            requirement. <br />
            AVCOM design and supply scalable, standard-based networking products
            that address a wide range of customers business needs, including:
            improving productivity, reducing costs, and gaining a competitive
            advantage. Our technology. <br />
            focuses on delivering networking products and systems that simplify
            customer’s infrastructure, offering integrated services and
            providing a high level of security. Our products and services help
            customers build their own network infrastructures while providing
            tools to allow them to consistently communicate securely with
            customers, prospects, business partners, suppliers, and employees.{" "}
            <br />
            Our expertise in Telecom Access, IT networking and Digital
            Television Technologies is focused on providing bundled solutions to
            our customers backed by timely and effective service. Our goal is to
            truly implement our vision with high quality products, after sales
            support, and longer warranties where such product diversifications
            are growing requirements. Our customers trust our technological
            strengths to design and develop customised products for mass
            deployment on their networks for voice, data and video services.{" "}
            <br />
            Our wide ranges of products address most of the last mile segments
            on legacy and fiber networks. We are committed to developing
            challenging and futuristic products for the NGN (Next Generation
            Networks) for fixed line Telcos and for wireless providers with
            highly enhanced applications and features.
            <h4 className="inner-heading-of-about-ant">
              We truly provide the Most Reliable Networking Products as below
              </h4>
            <ol>
              <li>Leased Line Modems</li>
              <li>Media Converters</li>
              <li>Modems</li>
              <li>Routers</li>
              <li>Ethernet Switches</li>
              <li>Internet Cameras</li>
              <li>Networking Equipment</li>
            </ol>
          </p>
        </div>
        <div className="product-service-text-box product-service-text-box-more-about-ant">
          <h3
            className="color-of-heading-is-orange"
            style={{ fontSize: "1.5rem" }}
          >
            B. SOLAR SOLUTIONS
          </h3>
          <p>
            We are a renowned System integrator of solar photovoltaic Systems
            and also undertake turnkey solutions. We offer the highest
            performing solar energy systems in the industry to power businesses,
            government and agricultural facilities and homes. Our team has
            extensive experience and great passion for the business. We closely
            link modeling, building, and monitoring to provide solar solutions
            that give the highest return on investment. We have built solid
            relationships with the top vendors in the industry, which enables us
            to get the solar panels and material needed to build systems on
            schedule. Our premier inhouse design and installation teams deliver
            carefree, turnkey solar systems to our customers, who love our
            world-class customer care. <br />
            Our products offers include Solar street lights, solar home lighting
            system, Inverters, Chargers, batteries, and other associated
            equipment. <br />
            We are a pioneer in Solar Energy field and supply Solar Products
            since 1999, and also Solar Energy Equipment in India to various
            Govt. Departments & Energy Development Agencies, Hospitals,
            Industries, Educational Institutes, Defence, Police Establishments,
            Indian Railways, Hotels, Rest Houses, Clubs, VIPs, Private users,
            etc. <br />
            <br />A number of our equipment, Solar Water Heating Systems and
            their components are tested by Solar Energy Centre, MNRE (Govt. of
            India), ETDC, SPRERI, IEC 61215 certified and are ISI marked. The
            investment cost on Solar Systems is recovered within 2 - 5 years
            because of saving in energy / fuel bills.
          </p>
        </div>

        <div className="product-service-text-box product-service-text-box-more-about-ant">
          <h3
            className="color-of-heading-is-orange"
            style={{ fontSize: "1.5rem" }}
          >
            C. CLOSE CIRCUIT TV SY STEMS:-
          </h3>
          <p>
            Avcom Network technologies have earned enviable reputation in
            providing a host of CCTV solutions to some of the most demanding
            customers like Colleges, Department of Deputy Commissioner Office ,
            Department of Information Technology Delhi, R & D Organizations,
            major international retail outlets, <br />
            Hospitals and Airports. We offer complete range of CCTV, CD, DVD &
            Blu-ray Evidence Disc printing with serial numbering & bar-co ding,
            indents & corporate logos. <br />
            In addition, a range of associated products such as CCTV Management
            Documentation, CCTV Visit or Logbooks, CCTV log sheets with removal
            forms, CPS approved media evidence bags, and a range of digital
            media are available which provide evidence capture & evidence
            presentation to the relevant authorities from digital CCTV systems.
          </p>
        </div>

        <div className="product-service-text-box product-service-text-box-more-about-ant">
          <h3
            className="color-of-heading-is-orange"
            style={{ fontSize: "1.5rem" }}
          >
            D. SUPPLY OF CONSUM ABLES AND OTHER MATERIAL
          </h3>
          <p>
            The following are supplied to our valued clients;
            <br />
            <ul>
              <li> Printer Toners for different printers</li>
              <li>Cartridges for different printers</li>
              <li>. Quality Stationery Items</li>
              <li>Quality Grade papers</li>
            </ul>
          </p>
        </div>

        <div className="product-service-text-box product-service-text-box-more-about-ant">
          <h3
            className="color-of-heading-is-orange"
            style={{ fontSize: "1.5rem" }}
          >
            SUPPORT SERVICES:-
          </h3>
          <p>
            The company provides comprehensive maintenance support for all
            products supplied by it or third party. We offer Onsite support for
            Delhi, NCR & Surrounding areas, with or without spares supply, as
            per customer needs and requirements.
            <br />
            Our team of fully trained and experienced Engineers & technicians
            provides prompt and effective services to all our valued clients. A
            response time of 2 to 3 hours and resolution time of one working day
            have established us as the most sought after Maintenance and
            servicing organization. The technicians are effectively monitored
            and supervised by supervisors and an experienced Manager as Head of
            Maintenance and Services. <br />
            We take pride in maintaining an equipment uptime of 98% to 100%,
            something that has earned us the unwavering loyalty of our
            customers. Suffice it to say that all our clients have stayed with
            us without any one of them leaving us for any other Servicing
            organization.
          </p>
        </div>


        
        <div className="product-service-text-box product-service-text-box-more-about-ant">
          <h3
            className="color-of-heading-is-orange"
            style={{ fontSize: "1.5rem" }}
          >
           CLINTELE
          </h3>
          <p>Total Commitment to Customer satisfaction, relentless pursuit of excellence, cost
effective solutions and systems integration approach and striving for that extra
mile are some of the vital factors that have earned Avcom Network Technologies
a host of loyal and reputed clients like Directorate of Training & Technical
Education Delhi, Delhi Technological University, Guru Teghbahadur Hospital,
Shahdara, Attar Sain Hospital, Maulana Azad Institute, Delhi Pollution Control
Committee, Delhi Commission For Protection of Child Rights (DCPCR), ITI Mori
Gate, ITI Jahangirpuri, ITI Jaffarpur, ITI Narela, Department of Audit, Delhi
Subordinate Services Selection Board, Delhi Dispute Resolution Society, Drugs
Control Department, MTNL, Delhi Jal Board, Secretariat of GOVT. of NCT of Delhi,
Delhi State Health Mission, Department of Delhi Home Guards, Employment,
Ambedkar Institute Of Technology Delhi, Environment Department, Municipal
Corporation of Delhi, DC Offices and many more. ……… <br /><br /><br />Avcom Network Technologies has been catering to the IT needs of these clients
for over twelve years. A mute testimony to their customers’ satisfaction approach
is that more than 95% of the clientele has stayed with them since inception and
70% of its total business comes from repeat orders.</p> <br /><br />
<h4 className="inner-heading-of-about-ant" style={{textAlign:'justify'}}>
So if you need a partner who can comprehend and cater to your Computer, IT,
Solar or CCTV needs and provide prompt personalized services on a long term
basis you may call us or mail to us. Our representative will promptly contact you
with customized solutions
              </h4>
          </div>  
        <br /><br />
      </div>
    </div>
  );
};

export default Aboutus;
