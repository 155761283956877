import React,{useState, useEffect} from 'react'
import { useNavigate,Link } from 'react-router-dom'
import singup from '../Images/signup.jpg'
import { FaEye } from "react-icons/fa";

const Login = () => {
  const [email, setEmail]=useState('');
  const [password, setPassword]=useState('');
  const[hidePassword, showPassword]=useState(false)

  const navigate= useNavigate();

  useEffect(()=>{
    const auth= localStorage.getItem('user');
    if(auth){
      navigate('/')
    }
  },[])

  // API
  const handleLogin = async()=>{
    console.warn(email, password);
    let result= await fetch('http://localhost:5000/login',{
      method:'post',
      body:JSON.stringify({email, password}),
      headers:{
        'Content-Type':'application/json'
      }
    });

    result = await result.json();
    console.warn(result)
    if(result.name)
    {
      localStorage.setItem("user",JSON.stringify(result));
      navigate('/')
    }
    else{
      alert('Encorrect details...')
    }
  }
  return (
    <div className="home-component">
    <div className="home-image-container">
      <img src={singup} alt="xxx" />
    </div>
    <div className="text-of-home-page">
      <p data-text="REGISTER">  Login </p>
    </div>

<div className="input-form-signup-container">
    <h1 className="input-form-singup-heading">
Login
    </h1>

    <div className="input-box-signup-box">
        <input type="email" className='inputBox' value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Enter email here'/>

<div className="input-box-password-cntainer">
        <input type={hidePassword?'text':'password'} className='inputBox password-inp-box' value={password} onChange={(e)=>setPassword(e.target.value)} placeholder='Set Your Password'/> 
        
        {hidePassword? <button onClick={()=>showPassword(!hidePassword)}  className='hide-show-button-password showed-password'><FaEye/> </button>:<button onClick={()=>showPassword(!hidePassword)}  className='hide-show-button-password hide-passord-eye'><FaEye/></button>
        
    }
    
    </div>

        <br /> <br /><br />

        
        <button className="submit-signup-button" onClick={handleLogin}>Login</button>
        <br /><br />
{/* 
        <div className="sign-up-btn-in-login">
          <p>Don't have account..</p> <Link to='/signup'>Signup</Link>
        </div> */}
    </div>
</div>

    </div>
  )
}

export default Login
