import React from "react";
import { motion } from "framer-motion";
import "./Home.css";
import homeimg from "../Images/homedp (2).jpg";
import servicepic from "../Images/laptop-8778592_1280.jpg";
import softwarepic from "../Images/software.jpg";
import hardwarepic from "../Images/hardware.png";

import dell from "../Images/Dell logo.png";
import uniline from "../Images/Logo-High-Res_1w-logo.webp";
import dlink from "../Images/D-Link-Logo.png";
import microtec from "../Images/altgr_type_keyboard_icon-512.webp";
import voltrics from "../Images/voltric-.webp";
import acer from "../Images/Acer logo.png";
import hplogo from "../Images/hp logo.png";
import netgear from "../Images/Netgear-logo.png";
import antlogo from "../Images/ant logo.png";

import { FaLaptopCode, FaLaptop, FaTools, FaMouse } from "react-icons/fa";
const Home = () => {
  return (
    <div className="home-component">
      <div className="home-image-container">
        <img src={homeimg} alt="xxx" />
      </div>
      <div className="text-of-home-page">
        <p> Avcom Network Technologies </p>
      </div>

      <div className="about-us-home-container">
        <motion.div
          className="box-of-about-avcom-home"
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.9 }}
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 2 }}
        >
          <div className="heading-and-full-name-of-ant">
            <h2 className="heading-of-about-company">
              <img src={antlogo} className="avcom-logo-ant" alt="ant" />
            </h2>
            <h6 className="full-name-ant">Avcom Network Technologies &nbsp;&nbsp;. </h6>
          </div>

          <div className="details-text-of-about-company">
            AVCOM NETWORK TECHNOLOGIES, one of the leading IT solutions company
            in Delhi/NCR, specialize in providing complete end to end solutions
            in Computers, Networking, IT and Solar Lighting systems (Street
            lights, Home Lighting System, Solar Water Heater etc.) Another area
            of specialization, among other services, is providing Annual
            Maintenance Services for computers and accessories to leading
            companies. We are empanelled with IT department of Delhi Government
            for supporting and maintaining crucial Computer installations in
            Delhi Government offices.
          </div>
        </motion.div>

        <div className="services-list-of-avcom">
          <div className="each-box-of-service">
            <FaLaptop className="icons-of-service" />{" "}
            <div className="service-of-ant">Hardware</div>{" "}
          </div>

          <div className="each-box-of-service">
            <FaLaptopCode className="icons-of-service" />{" "}
            <div className="service-of-ant">Software</div>
          </div>

          <div className="each-box-of-service">
            <FaTools className="icons-of-service" />{" "}
            <div className="service-of-ant">AMC</div>
          </div>
          <div className="each-box-of-service">
            <FaMouse className="icons-of-service" />{" "}
            <div className="service-of-ant">Parts</div>
          </div>
        </div>
      </div>

      {/* Our Product and services */}

      <div className="our-product-and-services-box">
        <div className="product-service-text-box">
          <h3>PRODUCTS & SERVICES</h3>
          <p>
            <b>AVCOM NETWORK TECHNOLOGIES</b> maintains Computer Systems & IT
            Products in many leading Government Organizations & departments like
            Directorate of Training & Technical Education Delhi, Delhi
            Technological University, Department of Audit, Delhi Subordinate
            Services Selection Board, Delhi Dispute Resolution Society, Delhi
            Pharmaceutical Sciences and Research University (DPSRU), Drugs
            Control Department, MTNL, Delhi Jal Board, Secretariat of GOVT. of
            NCT of Delhi, Delhi State Health Mission, Department of Delhi Home
            Guards, GTB Hospital, Attar Sain Hospital, Transport Department,
            Delhi, ITIs at Dheerpur, Jahangirpuri, Mori gate etc, Indian Army,
            to name a few.
          </p>
        </div>

        <div className="product-service-img-box">
          <img src={servicepic} alt="ANT" />
        </div>
      </div>

      {/* NEVER COMPROMISE container   */}

      <div className="never-compromise-section">
        <h2 className="never-compromise-heading-1">Never compromise</h2>
        <h2 className="never-compromise-heading-2">TECHNOLOGY ALLIANCES</h2>

        <div className="flip-box-all-container">
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <img src={dell} alt="software" />
              </div>
              <div className="flip-box-back">
                <img src={dell} alt="software" />
              </div>
            </div>
          </div>

          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <img src={hplogo} alt="hardware" />
              </div>
              <div className="flip-box-back">
                <img src={hplogo} alt="hardware" />
              </div>
            </div>
          </div>

          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <img src={acer} alt="acer" />
              </div>
              <div className="flip-box-back">
                <img src={acer} alt="acer" />
              </div>
            </div>
          </div>

          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <img src={voltrics} alt="acer" />
              </div>
              <div className="flip-box-back">
                <img src={voltrics} alt="acer" />
              </div>
            </div>
          </div>

          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <img src={netgear} alt="acer" />
              </div>
              <div className="flip-box-back">
                <img src={netgear} alt="acer" />
              </div>
            </div>
          </div>

          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <img src={dlink} alt="acer" />
              </div>
              <div className="flip-box-back">
                <img src={dlink} alt="acer" />
              </div>
            </div>
          </div>

          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <img src={uniline} alt="acer" />
              </div>
              <div className="flip-box-back">
                <img src={uniline} alt="acer" />
              </div>
            </div>
          </div>

          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <img src={microtec} alt="acer" />
              </div>
              <div className="flip-box-back">
                <img src={microtec} alt="acer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
