import React, { useEffect, useState } from "react";

import "./Nav.css";
import { NavLink } from "react-router-dom";
import { SlList } from "react-icons/sl";
import logo from "../../unnamed.png";
import { FaHeadset } from "react-icons/fa";

const Nav = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const shouldBeScrolled = scrollTop > 0;

      setIsScrolled(shouldBeScrolled);
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array means this effect runs only once when the component mounts

  const auth = localStorage.getItem("user");
  return (
    <div
      className={
        isScrolled ? "scrolled navigation-component" : " navigation-component"
      }
    >
      <div
        className={
          isNavOpen
            ? "navigation-container nav-is-opend-for-responsive"
            : "navigation-container"
        }
      >
        <div className="comany-logo-in-nav">
          {/* <img src={logo} alt="logo" /> */}
        </div>

        <div className="navigation-link-container responsive-nav-for-big-screen">
         
          <NavLink className="avcom-navlink" to="/">
            Home
          </NavLink>
          <NavLink className="avcom-navlink" to="/about-us">
            About-us
          </NavLink>
          <NavLink className="avcom-navlink" to="/contact-us">
            Contact-us
          </NavLink>
          {/* <NavLink className="avcom-navlink" to="/blogs">
            Blogs
          </NavLink> */}
          {auth ? (<>
            <NavLink className="avcom-navlink " to="/admin-panel">
              Admin Panel{" "}
            </NavLink>
             <NavLink className="avcom-navlink"  style={{color:'red'}}>
             Hello!  {JSON.parse(auth).name}
             </NavLink> </>
          ) : null}
          <NavLink
            className="avcom-navlink avcom-navlink-support"
            to="/support-form"
          >
            Support <FaHeadset />
          </NavLink>

         
        </div>

        {/* Responsive navigation for phone */}

        <button
          className="responsive-nav-btn"
          onClick={() => setIsNavOpen(!isNavOpen)}
        >
          <SlList />
        </button>

        {isNavOpen ? (
          <div className="navigation-link-container responsive-navigation-for-mob-screen">
            <NavLink className="avcom-navlink" to="/">
              Home
            </NavLink>
            <NavLink className="avcom-navlink" to="/about-us">
              About-us
            </NavLink>
            <NavLink className="avcom-navlink" to="/contact-us">
              Contact-us
            </NavLink>
            <NavLink
              className="avcom-navlink avcom-navlink-support"
              to="/support-form"
            >
              Support <FaHeadset />
            </NavLink>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Nav;
